:root {
  --background-color-light: #ffffff;
  --background-color-dark: #121212;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --primary-color: #61dafb;
  --secondary-color: #282c34;
  --font-family: Georgia, "Times New Roman", serif;
  --background-light: #ffffff;
  --text-light: #333333;
  --link-light: #2c3e50;
  --link-hover-light: #1a252f;
  --border-light: #e0e0e0;
  --nav-bg-light: #f8f9fa;
  --tag-bg-light: #e0e0e0;
  --tag-text-light: #333333;
  --button-text-light: #ffffff;
  --button-bg-light: #2c3e50;
  --background-dark: #1a1a1a;
  --text-dark: #ffffff;
  --link-dark: #9ca3af;
  --link-hover-dark: #e5e7eb;
  --border-dark: #2d2d2d;
  --nav-bg-dark: #2d2d2d;
  --tag-bg-dark: #3d3d3d;
  --tag-text-dark: #e0e0e0;
  --button-text-dark: #ffffff;
  --button-bg-dark: #3d3d3d;
}

[class="dark"] {
  --background: var(--background-dark);
  --text: var(--text-dark);
  --link: var(--link-dark);
  --link-hover: var(--link-hover-dark);
  --border: var(--border-dark);
  --nav-bg: var(--nav-bg-dark);
  --tag-bg: var(--tag-bg-dark);
  --tag-text: var(--tag-text-dark);
  --button-text: var(--button-text-dark);
  --button-bg: var(--button-bg-dark);
}

[class="light"] {
  --background: var(--background-light);
  --text: var(--text-light);
  --link: var(--link-light);
  --link-hover: var(--link-hover-light);
  --border: var(--border-light);
  --nav-bg: var(--nav-bg-light);
  --tag-bg: var(--tag-bg-light);
  --tag-text: var(--tag-text-light);
  --button-text: var(--button-text-light);
  --button-bg: var(--button-bg-light);
}

body {
  background-color: var(--background);
  color: var(--text);
  transition: background-color 0.3s, color 0.3s;
}

.App {
  text-align: center;
  padding: 20px;
  font-family: var(--font-family);
  font-size: 22px;
  max-width: 800px;
  margin: 0 auto;
}

button {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  transition: opacity 0.3s;
  font-family: var(--font-family);
}

button:hover {
  opacity: 0.8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--background-color-dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  color: var(--text-color-dark);
}

.App-link {
  color: var(--primary-color);
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: center;
  background: var(--secondary-color);
  border-radius: 8px;
  margin: 0.5rem 0;
}

.tag-cloud a {
  color: var(--text-color-dark);
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  background: var(--background-color-dark);
}

.tag-cloud a:hover {
  background: var(--primary-color);
  transform: scale(1.1);
}

body.light .tag-cloud {
  background: var(--background-color-light);
}

body.light .tag-cloud a {
  color: var(--text-color-dark);
}

.rating {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.theme-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.theme-icon.light {
  transform: rotate(0deg);
}

.theme-icon.dark {
  transform: rotate(180deg);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  text-align: center;
  padding: 20px;
  background-color: var(--secondary-color);
  color: var(--text-color-dark);
  border-radius: 8px;
  margin-top: 20px;
}

body.light footer {
  background-color: var(--nav-bg-light);
  color: var(--text-light);
}

footer a {
  color: var(--primary-color);
  text-decoration: none;
}

footer svg {
  width: 24px;
  height: 24px;
  fill: var(--primary-color);
  transition: fill 0.3s ease-in-out;
  margin: -5px 10px;
}

img {
  max-width: 100%;
}

p {
  white-space: pre-line;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  margin: 1rem auto;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.tags strong {
  margin-right: 10px;
}

.footer-copyright p {
  font-size: x-small;
  text-align: center;
  padding: 10px 0;
}

.footer-disclaimer p {
  font-size: xx-small;
  color: #808080;
  font-family: monospace;
  text-align: left;
  padding: 10px 0;
}

.footer-debug {
  background-color: var(--secondary-color);
  color: var(--text-color-dark);
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  font-size: x-small;
}

.publication-date {
  font-size: 0.9em;
  color: var(--text-color-dark);
  margin-bottom: 20px;
  font-style: italic;
}

body.light .publication-date {
  color: var(--text-color-light);
}

.stories-grid {
  display: grid;
  gap: 2rem;
  margin: 2rem 0;
}

.story-card {
  background: var(--background-color-dark);
  border-radius: 8px;
  padding: 1.5rem;
  transition: box-shadow 0.3s ease;
  margin-bottom: 2rem;
}

.story-card h1, .story-card h2 {
  text-transform: uppercase;
}

body.light .story-card {
  background: var(--background-color-light);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.story-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.story-thumbnail {
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  margin: 1rem 0;
}

.story-metadata {
  color: var(--text-color-dark);
  opacity: 0.8;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  font-style: italic;
  text-align: center;
}

body.light .story-metadata {
  color: var(--text-color-light);
}

.read-more-link {
  display: inline-block;
  margin-top: 1rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: all 0.2s;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: var(--secondary-color);
}

.read-more-link:hover {
  background: var(--primary-color);
  color: var(--background-color-dark);
  transform: translateX(4px);
}

.story-tags {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.tag-link {
  font-size: 0.9rem;
  color: var(--text-color-dark);
  opacity: 0.8;
  text-decoration: none;
  transition: all 0.2s;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  background: var(--secondary-color);
}

.tag-link:hover {
  opacity: 1;
  background: var(--primary-color);
  color: var(--background-color-dark);
}

.pagination {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin: 2rem 0;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: none;
  background: var(--secondary-color);
  color: var(--text-color-dark);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination button.active {
  background: var(--primary-color);
}

.telegram-promo {
  margin: 2rem 0;
  padding: 2rem;
  background: var(--secondary-color);
  border-radius: 8px;
  text-align: center;
}

.telegram-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #0088cc;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.telegram-button:hover {
  background: #006699;
  transform: translateY(-2px);
}

.telegram-button svg {
  width: 24px;
  height: 24px;
}

body.light .telegram-promo {
  background: var(--background-color-light);
}

.story-content {
  margin: 2rem 0;
  line-height: 1.6;
  font-size: 1.3rem;
  white-space: pre-line;
}

.story-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  padding: 1rem;
  background: var(--secondary-color);
  border-radius: 8px;
}

body.light .story-rating {
  background: var(--background-color-light);
}

.story-rating h5 {
  margin: 1rem 0;
  font-size: 1.1rem;
}

.rating-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.error-message {
  color: #ff4444;
  margin-top: 0.5rem;
}

.story-list-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-color-dark);
  opacity: 0.8;
}

body.light .story-list-rating {
  color: var(--text-color-light);
}

.nav-menu {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: var(--nav-bg);
  border-bottom: 1px solid var(--border);
}

.nav-menu-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1.2rem;
  border-radius: 12px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 500;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--link);
  text-transform: uppercase;
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  color: var(--link-hover);
}

.nav-link.random-button {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 0.7rem 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.nav-link.random-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.nav-icon {
  font-size: 1.2rem;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--text);
  padding: 8px;
}

.theme-toggle:hover {
  transform: rotate(360deg);
}

@media (max-width: 768px) {
  .App {
    padding: 0 20px;
    font-size: 16px;
    max-width: 768px;
    margin: 0 auto;
  }
  .nav-menu {
    flex-direction: column;
    padding: 0.5rem;
  }
  .nav-menu-container {
    flex-direction: column;
    width: 100%;
  }
  .nav-link {
    width: 100%;
    justify-content: center;
    padding: 0.5rem 0rem;
  }
  .theme-toggle {
    margin-top: 0.5rem;
  }
  .story-content {
    font-size: 16px;
  }
  .stories-grid {
    display: block;
  }
  button {
    width: 100%;
    margin-top: 10px;
  }
}

.random-story-header {
  text-align: center;
  margin-bottom: 2rem;
}

.random-story-button {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.random-story-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--text-color);
  animation: blink 1.5s infinite ease-in-out;
}

.tag {
  background-color: var(--tag-bg);
  color: var(--tag-text);
  padding: 4px 8px;
  border-radius: 4px;
  margin: 2px;
  display: inline-block;
  transition: all 0.2s;
}

.tag:hover {
  opacity: 0.8;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text);
  font-weight: 600;
}

.flip-clock-theme {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 2rem;
}

/* Глобальный глитч-эффект для всех элементов */
body.glitch-active *:not(.gengar-image) {
  animation: glitch-animation 0.5s infinite;
}


@keyframes glitch-animation {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  20% {
    transform: translate(-3px, 3px);
    opacity: 0.9;
  }
  40% {
    transform: translate(3px, -3px);
    opacity: 0.8;
  }
  60% {
    transform: translate(-3px, 3px);
    opacity: 0.9;
  }
  80% {
    transform: translate(3px, -3px);
    opacity: 0.8;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

/* Цветные тени для блоков и текста */
body.glitch-active *:not(.gengar-image)::before,
body.glitch-active *:not(.gengar-image)::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  mix-blend-mode: color-dodge;
  opacity: 0.5;
}

body.glitch-active *:not(.gengar-image)::before {
  animation: glitch-color-1 1s infinite;
  background: rgba(148, 0, 211, 0.3);
  clip-path: inset(45% 0 45% 0);
}

body.glitch-active *:not(.gengar-image)::after {
  animation: glitch-color-2 1s infinite;
  background: rgba(75, 0, 130, 0.3);
  clip-path: inset(30% 0 30% 0);
}

@keyframes glitch-color-1 {
  0% { transform: translate(0); }
  20% { transform: translate(-5px, 5px); }
  40% { transform: translate(5px, -5px); }
  60% { transform: translate(-5px, 5px); }
  80% { transform: translate(5px, -5px); }
  100% { transform: translate(0); }
}

@keyframes glitch-color-2 {
  0% { transform: translate(0); }
  20% { transform: translate(5px, -5px); }
  40% { transform: translate(-5px, 5px); }
  60% { transform: translate(5px, -5px); }
  80% { transform: translate(-5px, 5px); }
  100% { transform: translate(0); }
}

/* Дрожание элементов */
@keyframes shake {
  2% { transform: translate(1px, 0) skew(1deg); }
  4% { transform: translate(-1px, 0) skew(-0.5deg); }
  6% { transform: translate(0, 1px) skew(0.5deg); }
  8% { transform: translate(0, -1px) skew(-1deg); }
  10% { transform: translate(0); }
  100% { transform: translate(0); }
}

/* Дрожание элементов */
body.glitch-active *:not(.gengar-image) {
  animation: shake 0.8s cubic-bezier(.25,.1,.25,1) infinite;
}

/* Фиолетовый фон для body */
body.glitch-active {
  background: linear-gradient(45deg, #24003d, #2a003c); /* Градиент из темного фиолетового в светлый */
  color: var(--text-color-dark);
}